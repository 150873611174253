import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin, map, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { customDate, WorkListAction } from '../../interface/common.interface';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private dataService: DataService) {}

  public getOverallProgress(streamID: string | undefined): Observable<any> {
    // const waveAPI = this.http.get(`${environment.BASE_URL}stream/${streamID}/worklist/?client_id=${environment.LUNA_CLIENT_ID}&overall_progress=True`);
    // const cartonAPI =  this.http.get(`${environment.BASE_URL}stream/${streamID}/containers/?client_id=${environment.LUNA_CLIENT_ID}&overall_progress=True`);
    // const orderAPI = this.http.get(`${environment.BASE_URL}stream/${streamID}/orders/?client_id=${environment.LUNA_CLIENT_ID}&overall_progress=True`);
    // return forkJoin([ orderAPI, waveAPI, cartonAPI]);
    return this.http.get(
      `${environment.BASE_URL}stream/${streamID}/progress/client/${environment.LUNA_CLIENT_ID}`
    );
  }

  public getWaveProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode === 'in progress') {
      params = params.set('worklist_status', 'open');
    } else if (statusMode === 'completed') {
      params = params.set('worklist_status', 'closed');
    } else if (statusMode === 'cancelled') {
      params = params.set('worklist_status', 'cancelled');
    }

    const API = `${environment.BASE_URL}worklist/`;
    return this.http.get(API, { params });
  }

  public getOrderProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode === 'in progress') {
      params = params.set('order_status', 'open');
    } else if (statusMode === 'completed') {
      params = params.set('order_status', 'closed');
    } else if (statusMode === 'cancelled') {
      params = params.set('order_status', 'cancelled');
    }

    const API = `${environment.BASE_URL_ORDER}orders/`;
    return this.http.get(API, { params });
  }

  public getCartonProgress(
    streamID: string | undefined,
    page: number = 1,
    count: number = 10
  ): Observable<any> {
    let params = new HttpParams()
      .set('stream_type_id', streamID || '')
      .set('container_type', 'carton')
      .set('sort', 'end_date')
      .set('order', 'asc')
      .set('page', page.toString())
      .set('page_size', count.toString());

    const statusMode = this.dataService.statusMode.getValue();
    if (statusMode === 'in progress') {
      params = params.set('container_status', 'open');
    } else if (statusMode === 'completed') {
      params = params.set('container_status', 'closed');
    } else if (statusMode === 'cancelled') {
      params = params.set('container_status', 'cancelled');
    }

    const API = `${environment.BASE_URL_PRODUCT}containers/`;
    return this.http.get(API, { params });
  }

  public getWaveMetaData(id: string): Observable<any> {
    const API = `${environment.BASE_URL}worklist/${id}`;
    return this.http.get(API);
  }

  public getOverallOrderProgress(
    streamID: string | undefined
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamID}/orders/?client_id=${environment.LUNA_CLIENT_ID}&overall_progress=True`
    );
  }

  public getOverallCartonProgress(
    streamID: string | undefined
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamID}/containers/?client_id=${environment.LUNA_CLIENT_ID}&overall_progress=True`
    );
  }

  public getAllStream(): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/type?client_id=${environment.LUNA_CLIENT_ID}`
    );
  }

  public getAllStreamStages(id: string): Observable<any> {
    return this.http.get(`${environment.BASE_URL}stage/?category_id=${id}`);
  }

  public getAllViews(): Observable<any> {
    return this.http.get(`${environment.BASE_URL}view/`);
  }

  public getLiveConsoleData(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    const action = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions?page_number=${page}&page_size=${count}`
    );
    const event = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events?page_number=${page}&page_size=${count}`
    );
    return forkJoin([action, event]);
  }

  public getAllErrorsWarning(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events?page_number=${page}&page_size=${count}`
    );
  }

  public getAllAction(
    streamId: string | undefined,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions?page_number=${page}&page_size=${count}`
    );
  }

  public getLiveConsolePageData(
    streamId: string,
    type: string,
    typeID: string
  ): Observable<any> {
    let callType = '';
    if (type === 'carton detail') callType = 'containers';
    if (type === 'order detail') callType = 'order';
    if (type === 'wave detail') callType = 'worklist';
    const action = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/${callType}/${typeID}/actions`
    );
    const event = this.http.get(
      `${environment.BASE_URL}stream/${streamId}/${callType}/${typeID}/events`
    );
    return forkJoin([action, event]);
  }

  public getLiveConsoleAction(streamId: string | undefined): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/actions`
    );
  }
  public getLiveConsoleEvents(streamId: string | undefined): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/orders/events`
    );
  }

  public getCartonIDProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/containers/${externalId}/?page_number=${page}&page_size=${count}`
    );
  }

  public getOrderIDProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    let API = `${environment.BASE_URL}stream/${streamId}/orders/${externalId}/?page_number=${page}&page_size=${count}`;
    return this.http.get(API);
  }

  //search api for order deatil page
  public searchOrderData(
    streamId: string,
    externalId: string,
    type?: string,
    searchId?: string
  ): Observable<any> {
    let API = `${environment.BASE_URL}stream/${streamId}/orders/${externalId}/?type=${type}&id=${searchId}`;
    return this.http.get(API);
  }

  public getWaveIDProgress(
    streamId: string,
    externalId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}stream/${streamId}/worklist/${externalId}/?page_number=${page}&page_size=${count}`
    );
  }

  public searchProgressDetails(search_id: string): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}/stream/search_api/${search_id}`
    );
  }

  public getDivertedLaneData(
    streamId: string,
    page?: number,
    count?: number
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}streams/lanes/?page_number=${page}&page_size=${count}&client_id=${environment.LUNA_CLIENT_ID}`
    );
  }

  public getDivertedLaneDetail(
    streamId: string,
    lane: string
  ): Observable<any> {
    return this.http.get(
      `${environment.BASE_URL}streams/lanes/${lane}?client_id=${environment.LUNA_CLIENT_ID}`
    );
  }

  public getLaneMonitorLiveConsole(workList: WorkListAction): Observable<any> {
    const actions = this.http.post(
      `${environment.BASE_URL}stream/containers/actions/`,
      workList
    );
    const events = this.http.post(
      `${environment.BASE_URL}stream/containers/events/`,
      workList
    );
    return forkJoin([actions, events]);
  }

  // public getDateRange(): Observable<any> {
  //   return this.http.get(environment.BASE_URL + 'streams/reports/daterange/');
  // }

  public getDivertedPallentized(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL + 'streams/reports/cartons/palletized/download/?';
    } else {
      API = environment.BASE_URL + 'streams/reports/cartons/palletized/?';
    }
    const date = '2024-02-12 17:37:05.423';
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `&group_by=${groupBy}&is_all_time=true`;
      } else if (isLastWeek) {
        API = API + `&is_last_week=True&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `&is_last_month=True&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `&is_last_day=True&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getDivertedPallentizedAnomaly(
    time: string,
    pageSize: number,
    pageNumber: number,
    reason: string
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/cartons/palletized/anomaly/?page_size=${pageSize}&page=${pageNumber}&${time}=true&reason=${reason}`;
    return this.http.get(API);
  }

  public getTaskHealth(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/task_health/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL +
        'streams/reports/task_health/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true&group_by=${groupBy}`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `is_last_day=true&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getTaskHealthAnomaly(
    time: string,
    pageSize: number,
    pageNumber: number
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/task_health/anomaly/?page_size=${pageSize}&page=${pageNumber}&${time}=true`;
    return this.http.get(API);
  }

  // public getTaskHealthAnomalyMock(timePersiod: string, pageSize: number, pageNumber: number): Observable<any> {
  //   const API = `${environment.BASE_URL}streams/reports/task_health/anomaly/?page_size=${pageSize}&page_number=${pageNumber}&${timePersiod}=true`;
  //   return this.http.get(API)
  // }

  public getCornerstoneRecirculation(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/recirc/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL + 'streams/reports/recirc/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true&group_by=${groupBy}`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true&group_by=${groupBy}`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true&group_by=${groupBy}`;
      } else if (isLastDay) {
        API = API + `is_last_day=true&group_by=${groupBy}`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public getCornerstoneRecirculationAnomaly(
    timePersiod: string,
    pageSize: number,
    pageNumber: number
  ): Observable<any> {
    const API = `${environment.BASE_URL}streams/reports/recirc/anomaly/?page_size=${pageSize}&page=${pageNumber}&${timePersiod}=true`;
    return this.http.get(API);
  }

  public getDivertReconciliation(
    overall?: boolean,
    groupBy?: string,
    isLastWeek?: boolean,
    isLastMonth?: boolean,
    isLastDay?: boolean,
    download?: boolean,
    type?: string,
    custom?: customDate
  ): Observable<any> {
    let API = '';
    if (download) {
      API =
        environment.BASE_URL +
        'streams/reports/divertreconciliation/download/?page=1&page_size=100&';
    } else {
      API =
        environment.BASE_URL +
        'streams/reports/divertreconciliation/?page=1&page_size=100&';
    }
    if (custom && custom.start_date) {
      console.log('this is custom');
      API =
        API +
        `&start_date=${custom.start_date}&start_time=${custom.start_time}&group_by=${groupBy}&end_date=${custom.end_date}&end_time=${custom.end_time}`;
    } else {
      if (overall) {
        API = API + `is_all_time=true`;
      } else if (isLastWeek) {
        API = API + `is_last_week=true`;
      } else if (isLastMonth) {
        API = API + `is_last_month=true`;
      } else if (isLastDay) {
        API = API + `is_last_day=true`;
      }
    }
    API = API + `&type=${type}`;
    let httpReq;
    if (download) {
      httpReq = this.http.get(API, { responseType: 'blob' });
    } else {
      httpReq = this.http.get(API);
    }
    return httpReq;
  }

  public searchProgressById(id: string) {
    const API = `${environment.BASE_URL}stream/search/${id}`;
    return this.http.get(API);
  }

  public getLiveConsoleInfoData(
    id: string,
    status: string | undefined,
    type: string | undefined,
    page: number,
    pageSize: number
  ): Observable<any> {
    const API = `${environment.BASE_URL_PRODUCT}containers/remaining/${id}?status=${status}&type=${type}&client_id=${environment.LUNA_CLIENT_ID}&page=${page}&page_size=${pageSize}`;
    return this.http.get(API);
  }

  public getDivertLaneInfo(lane: string | undefined, position: string | undefined, page: number, page_size: number): Observable<any> {
    let API = `${environment.BASE_URL_PRODUCT}containers/remaining/lane/${lane}/position/${position}/?client_id=${environment.LUNA_CLIENT_ID}&page=${page}&page_size=${page_size}`;
    return this.http.get(API);
  }

}
